/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        /**
         * Retrieve cookie with javascript
         *
         * @param {string} name cookie name
         */
        function retrieve_cookie(name) {
          var cookie_value = "",
            current_cookie = "",
            name_expr = name + "=",
            all_cookies = document.cookie.split(';'),
            n = all_cookies.length;
         
          for(var i = 0; i < n; i++) {
            current_cookie = all_cookies[i].trim();
            if(current_cookie.indexOf(name_expr) === 0) {
              cookie_value = current_cookie.substring(name_expr.length, current_cookie.length);
              break;
            }
          }
          return cookie_value;
        }

        /**
         * Create cookie with javascript
         *
         * @param {string} name cookie name
         * @param {string} value cookie value
         * @param {int} days2expire
         * @param {string} path
         */
        function create_cookie(name, value, days2expire, path) {
          var date = new Date();
          date.setTime(date.getTime() + (days2expire * 24 * 60 * 60 * 1000));
          var expires = date.toUTCString();
          document.cookie = name + '=' + value + ';' +
                           'expires=' + expires + ';' +
                           'path=' + path + ';';
        }

        $(function(){
          var cookie_name = 'asphys_privacy_cookies_alert_accept';
          var cookie_value = 0;
          
          var res = parseInt(retrieve_cookie(cookie_name));
          
          if(res === 1) {
              $('.cookie-alert').hide();
          } else {
            $('.cookie-alert').fadeIn();
            create_cookie(cookie_name, 0, 30, "/");
          }

          $('#accept-cookies').click(function(event) {
            create_cookie(cookie_name, 1, 30, "/");
            $('.cookie-alert').fadeOut();
          });
        });
        /**
         * Lightbox for images, if image has attribute no-data-lightbox, lightbox will not initalized
         */
        $(function($) {
          $("a[href$='.jpg'],a[href$='.png'],a[href$='.gif']").each(function(index) {
            if ($(this).attr('no-data-lightbox')) {
              // alert('dsaibled');
              // $(this).attr('data-lightbox', '');
              return false;
            }
            if (typeof($(this).attr('data-lightbox')) === 'undefined') {
              $(this).attr('data-lightbox', 'lightbox' + index);
            }
          });
        });

        lightbox.option({
          'albumLabel': 'Zdjęcie %1 z %2'
        });
        $('#menu-main-navigation').slicknav({
          prependTo: '.nav-col'
        });

        $('#lang-switcher').hover(function() {
          /* Stuff to do when the mouse enters the element */
          $('.dropdown').stop().fadeIn();
        }, function() {
          /* Stuff to do when the mouse leaves the element */
          $('.dropdown').stop().fadeOut();
        });

        $('#acceptance-r-rules').change(function() {
          if ($(this).is(':checked')) {
            $('input#rules-r-checkbox').click();
              $('input#rules-r-checkbox').prop("checked", true);
          } else {
            $('input#rules-r-checkbox').click();
              $('input#rules-r-checkbox').prop("checked", false);
          }
        });

        $('#acceptance-r-rodo').change(function() {
          if ($(this).is(':checked')) {
            $('#rodo-r-checkbox input').click();
              $('#rodo-r-checkbox input').prop("checked", true);
          } else {
            $('#rodo-r-checkbox input').click();
              $('#rodo-r-checkbox input').prop("checked", false);
          }
        });

        $('#acceptance-c-rodo').change(function() {
          if ($(this).is(':checked')) {
            $('#rodo-c-checkbox input').click();
              $('#rodo-c-checkbox input').prop("checked", true);
          } else {
            $('#rodo-c-checkbox input').click();
              $('#rodo-c-checkbox input').prop("checked", false);
          }
        });

        $('.menu-toggler').click(function(event) {
          $('#menu-main-navigation').slicknav('toggle');
        });

        function navMakeFixed() {
          var body_top_margin = $('header.banner').outerHeight();
          if ($(window).scrollTop() > 0) {
            $('header.banner').addClass("nav-scrolled");
            $('.site').css('margin-top', body_top_margin);
          } else {
            $('header.banner').removeClass("nav-scrolled");
            $('.site').css('margin-top', 0);
          }
        }

        navMakeFixed();

        $(window).on('scroll', navMakeFixed);

        var offset_nav = $('header.banner').outerHeight();

        $('a').on('click', function(e) {

          $(document).off("scroll"); 

          var target = this.hash.slice(1),
          menu = target;

          if (target.length > 0) {

            $target = $('[name=' + target + ']');

            if ($target.length) {
                $('#menu-main-navigation').slicknav('close');
                // $('#menu-main-navigation a').each(function() {
                //     $(this).parent().removeClass("active").removeClass("current-menu-item");
                // });
                // $(this).parent().addClass('active');
                console.log(offset_nav);
                $('html, body').stop().animate({
                    'scrollTop': $target.offset().top - ( offset_nav )
                }, 300, 'swing', function() {
                    window.location.hash = target;
                    $(document).on("scroll", navMakeFixed);
                });
            }
          }
        });

        function roleBlocksMaxHeight ( element ){
          $roleRow = $(element);

          if ($roleRow) {
            var max=0;
            $roleRow.find('.role-item .role-item__prices').each(function(index, el) {
              if ( $(this).outerHeight() > max )
              {
                max = $(this).outerHeight();
              }
            });

            $roleRow.find('.role-item__prices').css('min-height', max);
          }
        }

        $(window).on('load', function(event) {
          
          roleBlocksMaxHeight('.row-roles');

          if (window.location.hash) {
            
            var offset_nav = $('header.banner').outerHeight();
            var hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
            // alert (hash);
            target = $('[name=' + hash + ']');
            if (target.length) {
              $('html,body').animate({
                scrollTop: target.offset().top- offset_nav
              }, 300);
              return true;
            }
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var offset_nav = $('header.banner').outerHeight()-10;

        $('.section-scroll__scroll-down').click(function(event) {
          $('html, body').stop().animate({
              scrollTop: $('.section-hero').next().offset().top-offset_nav
          }, 'slow');
        });

        $(window).on('scroll',  function() {
          if ($('.section-about').isOnScreen(function(deltas){
            return /*deltas.top >= $(window).height()/2 &&*/ deltas.bottom >= $(window).height()/2;
          }))
          {
            $('.section-about .image-1').addClass('animated bounceInRight');
            $('.section-about .image-2').addClass('animated bounceInRight');
          }
        });

        function flipsterSwitched(currentItem, previousItem) {         
          $('.dots__item[data-sub-id='+$(currentItem).attr('data-sub-id')+']').siblings().removeClass('dots__item--current');
          $('.dots__item[data-sub-id='+$(currentItem).attr('data-sub-id')+']').addClass('dots__item--current');

          $('#'+$(previousItem).attr('data-sub-id')).hide().removeClass('active');
          $('.how-it-works-content').hide().removeClass('active');
          $('#'+$(currentItem).attr('data-sub-id')).fadeIn().addClass('active');

          if ($(window).width() <= 991) {
            $('html, body').stop().animate({
                scrollTop: $('.section-how-it-works').offset().top-$('.nav-scrolled').outerHeight()
            }, 'slow');
          }
        }

        $oferta_flipster = $("#how-it-works-flipster");
        $oferta_flipster_mobile = $("#how-it-works-flipster-mobile");        

        $oferta_flipster_mobile.flipster({
          style: 'flat',
          spacing: -0.39,
          items: 5,
          nav: false,
          buttons: false,
          scrollwheel: false,
          loop: true,
          onItemSwitch: flipsterSwitched
        });

        $oferta_flipster.flipster({
          style: 'vertical',
          spacing: -0.5,
          vertical: true,
          vertical_items: 5,
          scrollwheel: false,
          loop: 5,
          center: true,
          onItemSwitch: flipsterSwitched
        });        
        
        $(window).on('load', function(){
          $('#'+$('.flipster__item--current').attr('data-sub-id')).addClass('active').fadeIn();
        });

        $('.page-section-offer').on('click', '.offer-up, .offer-left', function(event) {
          event.preventDefault();
          /* Act on the event */
          $oferta_flipster.flipster('prev');
          $oferta_flipster_mobile.flipster('prev');
        });

        $('.page-section-offer').on('click', '.offer-down, .offer-right', function(event) {
          event.preventDefault();
          /* Act on the event */
          $oferta_flipster.flipster('next');
          $oferta_flipster_mobile.flipster('next');
        });

        $('.dots__item').on('click', function(event) {
          event.preventDefault();
          var index = $(this).index();
          $(this).addClass('dots__item--current');
          $(this).siblings().removeClass('dots__item--current');
          $oferta_flipster.flipster('jump', index);
          $oferta_flipster_mobile.flipster('jump', index);
        });

        $('.nav__prev').on('click', function(event) {
          event.preventDefault();
          $oferta_flipster.flipster('jump', 'prev');
          $oferta_flipster_mobile.flipster('jump', 'prev');
        });

        $('.nav__next').on('click', function(event) {
          event.preventDefault();
          $oferta_flipster.flipster('jump', 'next');
          $oferta_flipster_mobile.flipster('jump', 'next');
        });

        $('.show-form').click(function(event) {
          var form_content = $(this).attr('data-form');

          if (form_content.length > 0) {
            $(this).addClass('show-form--current').siblings().removeClass('show-form--current');
            $(form_content).addClass('form-content--showed').siblings().removeClass('form-content--showed');
          }
        });


        function getPartnerName() {          
          var url_string = window.location.href;
          var url = new URL(url_string);
          var c = url.searchParams.get("nazwa_partnera");
          
          if (c) {
            if (c.length > 0) {
              $('#nazwa_partnera').val(c);
              alert(c);
            }
          }
        }

        getPartnerName();
        var owlVideos = $(".owl-videos");
        owlVideos.owlCarousel({
          items: 3,
          margin: 15,
          nav: true,
          dots: true,
          loop: true,
          // navText: ["<span class='nav-prev-icon'></span>", "<span class='nav-next-icon'></span>"],
          autoplay:false,
          // autoplayTimeout: 5000,
          // autoplayHoverPause:true,
          // smartSpeed: 500,
          // autoplaySpeed: 1000,
          responsiveClass:true,
          responsive : {
              0 : {
                  items : 1
              },
              768 : {
                  items : 1
              },
              1024: {
                  items: 3
              }
          }
        });

        owlVideos.on('change.owl.carousel', function(event) {
          $('.youtube-video').each(function(index, el) {
            $(el)[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
          });
        });

        var owlReviews = $(".owl-reviews");
        owlReviews.owlCarousel({
          margin: 15,
          nav: true,
          dots: true,
          loop: true,
          // navText: ["<span class='nav-prev-icon'></span>", "<span class='nav-next-icon'></span>"],
          autoplay: true,
          autoplayTimeout: 15000,
          // autoplayHoverPause:true,
          smartSpeed: 500,
          autoplaySpeed: 1000,
          responsiveClass: true,
          autoheight: false,
          responsive : {
              0 : {
                  items : 1
              },
              768 : {
                  items : 2
              },
              1024: {
                items: 3
              }
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_profits_page': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('.profit-item').click(function(event) {
          var form_content = $(this).attr('data-profit-item');

          if (form_content.length > 0) {
            $(this).addClass('profit-item--current').siblings().removeClass('profit-item--current');
            $(form_content).addClass('profit-item-content--showed').siblings().removeClass('profit-item-content--showed');
            $('.profit-item-title-mobile').html($(this).find('.profit-item__title').html());
          }
        });

        $(window).load(function() {
          $('.profit-item-title-mobile').html($('.profit-item:nth-child(1)').find('.profit-item__title').html());
        });
      }
    },
    'page_template_prices_page': {
      init: function() {

        function makeTableHeadingRowFixed() {
          
          if ($(window).scrollTop() > $('.Table-content').offset().top-$('.nav-scrolled').outerHeight())  {
            $('.Table-content .heading-row').height($('.Table-content .heading-row').height());
            $('.Table-content .heading-row').width($('.Table-content .heading-row').width());
            $('.heading-row th').each(function(index, el) {
              $(el).height($(el).height());
              $(el).width($(el).width());
            });

            $('.Table-content').addClass('fixed');
            $('.Table-content .heading-row').css('top', $('header.banner').outerHeight());
            $('.Table-content').css('padding-top', $('.Table-content .heading-row').height());
          } else {
            $('.Table-content').removeClass('fixed').css('top', 'auto');
            $('.Table-content .heading-row').style = '';
            $('.heading-row th').style = '';
            $('.Table-content').css('padding-top', '0');
          }

          if ( $(window).scrollTop() > ($('.Table-content').offset().top + $('.Table-content').height()) - $('.Table-content table tr:last-child').height() ) {
            $('.Table-content .heading-row').css('opacity', 0);
          } else {
            $('.Table-content .heading-row').css('opacity', 1);
          }
        }

        $(window).on('scroll', function(){
          makeTableHeadingRowFixed();
        });

        var scrolledToDiscount = false;
        var discountHideTimeOut;

        function calculatePrice() {
          var price = 0;
          var isSms = $('#sms-notification').is(':checked');

          $('.row-role').each(function(index, el) {
            var price_per_user = (isSms) ? $(el).data('price-sms') : $(el).data('price');
            var user_count = $($(this).data('related-input')).val();

            $(this).find('.row-role__price-text').text(price_per_user);
            $(this).find('.role-count-sum').text(price_per_user*user_count);

            price += (price_per_user*user_count);            
          });

          $('#price-sum').text(price);
          //Disable discount func.
          // if(price>500) {
          if(0) {
            $('.row-calculator').removeClass('align-items-center');
            $('.price-sum-info-discount').show().removeClass('animated bounceOutLeft');
            $('.price-sum-info-discount').removeClass('animated bounceOutLeft').addClass('animated bounceInRight');
            clearTimeout(discountHideTimeOut);

            $('.calculate-info').hide().show().addClass('animated slideInUp');
            
          } else {
            $('.row-calculator').addClass('align-items-center');
            scrolledToDiscount = false;
            $('.price-sum-info-discount').removeClass('animated bounceInRight').addClass('animated bounceOutLeft');
            
            discountHideTimeOut = setTimeout(function() {
              $('.calculate-info').removeClass('animated slideInUp').hide().show().addClass('animated slideInDown');
              $('.price-sum-info-discount').hide();
            }, 100);
          }
        }

        $('#sms-notification').on('change', function(event) {
          calculatePrice();
        });

        $('.role-count-modifier-minus').click(function(event) {
          var $input_to_modify = $($(this).data('input-to-modify'));

          if($input_to_modify.attr('min').length > 0 && $input_to_modify.attr('min') > ($input_to_modify.val()-1)) {
              
          } else {

            if($input_to_modify.length > 0) {
              var input_value = parseInt($input_to_modify.val());

              if (Number.isInteger(input_value) && --input_value > -1 ) {
                $input_to_modify.val(input_value);
              }
            }
          }

          calculatePrice();
        });

        $('.role-count-modifier-plus').click(function(event) {
          var $input_to_modify = $($(this).data('input-to-modify'));

          if($input_to_modify.length > 0) {
            var input_value = parseInt($input_to_modify.val());

            if (Number.isInteger(input_value) && ++input_value > -1 ) {
              $input_to_modify.val(input_value);
            }
          }

          calculatePrice();
        });

        $('input.input-role-count').on('keyup', function(event) {
          var input_value = parseInt($(this).val());

          if($(this).attr('min').length > 0 && $(this).attr('min') > $(this).val()) {
            $(this).val($(this).attr('min'));
            
          }

          if ($(this).val() === '') {
            if($(this).attr('min').length > 0) {
              $(this).val($(this).attr('min'));
            } else {
              $(this).val(0);
            }
          } else {
            $(this).val(parseInt($(this).val()));
          }

          calculatePrice();
        });

        $('input.input-role-count').on('change',  function(event) {         
          calculatePrice();
        });

        $('input.input-role-count').keydown(function (e) {
            // Allow: backspace, delete, tab, escape, enter and .
            if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
                 // Allow: Ctrl/cmd+A
                (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
                 // Allow: Ctrl/cmd+C
                (e.keyCode === 67 && (e.ctrlKey === true || e.metaKey === true)) ||
                 // Allow: Ctrl/cmd+X
                (e.keyCode === 88 && (e.ctrlKey === true || e.metaKey === true)) ||
                 // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 40)) {
                     // let it happen, don't do anything
                     return;
            }
            // Ensure that it is a number and stop the keypress
            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        });
        

        $(window).load(function() {
          calculatePrice();
        });

        var roles_options = [];

        $('.Table-content table tr').each(function(index, el) {
          if ($(el).find('th:not(:first-child)').length > 0) {
            
            $(el).find('th:not(:first-child)').each(function(index, el) {
              roles_options.push($(el).text());
            });
          }
        });

        var myDiv = document.getElementById("user-roles-select-container");
        var selectList = document.createElement("select");
        
        selectList.id = "user-roles-select";
        myDiv.appendChild(selectList);

        //Create and append the options
        for (var i = 0; i < roles_options.length; i++) {
            var option = document.createElement("option");
            option.value = i+1;
            option.text = roles_options[i];
            selectList.appendChild(option);
        }

        $('#user-roles-select').on('change', function(event) {
          event.preventDefault();
          var tab_index = parseInt($(this).val())+1;
          $('.Table-content table th:not(:first-child)').hide();
          $('.Table-content table th:nth-child('+tab_index+')').show();
          $('.Table-content table td:not(:first-child)').hide();
          $('.Table-content table td:nth-child('+tab_index+')').show();
          // $('.table-content-indexed').hide();
          // $('.table-content-index-'+$(this).val()).show();
          /* Act on the event */
        });

        $(window).load(function() {
          if ($(window).width() <= 991) {
            $('.Table-content table th:not(:first-child)').hide();
            $('.Table-content table th:nth-child('+2+')').show();
            $('.Table-content table td:not(:first-child)').hide();
            $('.Table-content table td:nth-child('+2+')').show();
          }
        });       
      }
    },
    'page_template_resources_page': {
      init: function() {

        $('.modal-yt').on('hide.bs.modal', function () {
          $('.youtube-video').each(function(index, el) {
            $(el)[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
          });
        });
      }
    },
    'page_template_faq_page': {
      init: function() {

        $('.faq-title[data-faq-id]').on('click', function(event) {
          event.preventDefault();
          var faq_id = $(this).attr('data-faq-id');

          if ($(this).hasClass('active')) {
            $('.faq-title').removeClass('active');
            $('.faq-content').removeClass('active');

          } else {
            $('.faq-title').removeClass('active');
            $('.faq-content').removeClass('active');
            $(this).addClass('active');
            $('.faq-content[data-faq-id="'+faq_id+'"]').toggleClass('active');            
          }
          
        });
      }
    },
    'single': {
      init: function() {
        $('.section-single-post-hero__scroll-down').click(function(event) {
          $('html, body').stop().animate({
              scrollTop: $('.section-single-post-hero').next().offset().top - ( $('header.banner').outerHeight() - $('.slicknav_menu').height() ) + 10
          }, 'slow');
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.